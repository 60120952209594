import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import styled from "styled-components";

import { Layout } from "./layout";
import SEO from "./seo";
import { Row, Column } from "./grid";
import { Paragraph } from "./paragraph";
import { Heading } from "./heading";

const GalleryHolder = styled(Column)`
  display: block;
`;

export function PortfolioPage({ pageContext }) {
  const { data } = pageContext;

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = data.photos.map(photo => {
    const { width, height } = photo.file.details.image;

    return {
      src: photo.file.url,
      width,
      height,
    };
  });

  return (
    <Layout subPage>
      <SEO title={data.title} />
      <Row>
        <Column xs={12}>
          <Heading>{data.title}</Heading>
        </Column>
      </Row>
      <Row>
        {data.content && (
          <Column xs={12} lg={6}>
            <Paragraph>{data.content.content}</Paragraph>
          </Column>
        )}

        <GalleryHolder>
          <Gallery photos={photos} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map(x => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </GalleryHolder>
      </Row>
    </Layout>
  );
}
